import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import VerifyOTP from "components/MFA/VerifyOTP";
import InterstitialPage from "components/templates/InterstitialPage";

import { MFAVerificationChannel } from "model/mfa";
import { homeScreenInitialisation, mfaAuthentication } from "services/authorizationService";
import userService from "services/userService";
import getMFASetupState from "redux/selectors/mfa";
import { history } from "App";
import * as ROUTES from "navigation/routes";

const MFAAuthentication: FC = () => {
    const dispatch = useDispatch();
    const { isMobileNumberReverificationRequired } = useSelector(getMFASetupState);
    const [channel, setChannel] = useState<MFAVerificationChannel>(MFAVerificationChannel.EMAIL);

    const switchChannel = (): void => {
        setChannel(
            channel === MFAVerificationChannel.EMAIL ? MFAVerificationChannel.SMS : MFAVerificationChannel.EMAIL
        );
    };

    const handleVerification = async (shouldTrustDevice): Promise<void> => {
        await mfaAuthentication(dispatch);
        if (shouldTrustDevice) await userService.saveTrustedDevice();

        if (isMobileNumberReverificationRequired) {
            history.push(ROUTES.MFA_REVERIFY_MOBILE_NUMBER);
        } else {
            homeScreenInitialisation(dispatch);
        }
    };

    return (
        <InterstitialPage>
            <VerifyOTP
                showTrustedDeviceCheckbox
                showVerificationChannelSwitch
                title="Verify your identity"
                channel={channel}
                switchChannel={switchChannel}
                handleVerification={handleVerification}
            />
        </InterstitialPage>
    );
};

export default MFAAuthentication;
