import { FC, KeyboardEvent } from "react";
import moment from "moment";
import { Table, Icon } from "semantic-ui-react";

import ConditionalRender from "components/templates/ConditionalRender";
import IntegrationPatientLabel from "components/templates/IntegrationPatientLabel";

import CaseListCellAction from "components/CaseList/CaseListCellAction";
import CaseListLabel from "components/CaseList/CaseListLabel";
import CaseListTableHeaderItem from "components/CaseList/CaseListTableHeaderItem";

import { getCaseStep, getPatientData as getCasePatientData } from "helpers/caseList";
import { getIsDeceasedPatient, getIsMergedRecordField } from "helpers/definedPersonalData";
import { getHospitalNumberClassName, getPatientData, getPatientField } from "helpers/patientUtils";
import {
    formatTimeWithHoursWithMinutes,
    getDatetimeOverdueOrSinceUpdate,
    singledDays,
    singledHours,
    singledMinutes,
    formatTime,
    formatTimeDOB,
    formatDatetimeDifference,
} from "helpers/datetime";

import { UserRole } from "model/userRole";
import { ICase, IPatientData } from "model/case";
import { CaseListColumns } from "model/caseList";
import { CaseStatus, CaseStatusDictionary, CaseStatusDictionaryPatient } from "model/caseStatus";
import { RemoteTimelineDictionary } from "model/remoteCaseTimeline";
import { PatientLabelTypes } from "model/patientSearch";
import PatientDetailsIntegration from "model/integrations";

import isPASIntegration from "helpers/PASintegration";

interface ICaseListTableCell {
    currentCase: ICase;
    column: string;
    patientData: IPatientData[];
    currentUserRole: string;
    redirectToCaseDetails: () => void;
    openCaseInNewTab?: (event: MouseEvent) => void;
}

const COLORS_MAP = {
    [CaseStatus.CASE_COMPLETED]: "grey",
    [CaseStatus.CASE_CLOSED]: "grey",
    [CaseStatus.CASE_ABANDONED]: "black",
};

const CaseListTableCell: FC<ICaseListTableCell> = ({
    currentCase,
    column,
    patientData,
    currentUserRole,
    redirectToCaseDetails,
    openCaseInNewTab,
}) => {
    const getManagementOutcome = (isSa: boolean) => {
        const clientReview = currentCase.reviews?.find((review) => review.safetyNetReview === isSa);
        const { reviewManagement } = { ...clientReview };

        return reviewManagement;
    };
    const isCurrentUserPatient = currentUserRole === UserRole.PATIENT;

    const handleKeyDown = (e: KeyboardEvent, handleFunction: () => void) => {
        if (e.key === "Enter") {
            handleFunction();
        }
    };

    switch (column) {
        case CaseListColumns.ID: {
            return (
                <Table.Cell className="case-list__cell">
                    <CaseListTableHeaderItem columnName={column} isCurrentUserPatient={isCurrentUserPatient} />
                    <div
                        className="case_id case-list__cell-container"
                        role="button"
                        onKeyPress={redirectToCaseDetails}
                        onClick={redirectToCaseDetails}
                        tabIndex={0}
                    >
                        {currentCase.caseId}
                        <ConditionalRender requiredRole={[UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SA_ADMIN]}>
                            <Icon
                                name="share square"
                                tabIndex={0}
                                onKeyPress={(e) => {
                                    e.stopPropagation();
                                    openCaseInNewTab(e);
                                }}
                                onClick={openCaseInNewTab}
                            />
                        </ConditionalRender>
                    </div>
                </Table.Cell>
            );
        }
        case CaseListColumns.DATE_CREATED: {
            const time = isCurrentUserPatient
                ? formatTime(currentCase.creationDate)
                : formatTimeWithHoursWithMinutes(currentCase.creationDate);

            return (
                <Table.Cell className="case-list__cell">
                    <CaseListTableHeaderItem columnName={column} isCurrentUserPatient={isCurrentUserPatient} />
                    <span
                        className="case-list__cell-container"
                        role="button"
                        onKeyPress={redirectToCaseDetails}
                        onClick={redirectToCaseDetails}
                        tabIndex={0}
                    >
                        {time}
                    </span>
                </Table.Cell>
            );
        }
        case CaseListColumns.PATIENT: {
            const firstName = getCasePatientData(patientData, "name");
            const secondName = getCasePatientData(patientData, "surname")?.toUpperCase();
            const patientFullName = `${secondName}, ${firstName}`;

            return (
                <Table.Cell className="case-list__cell">
                    <CaseListTableHeaderItem columnName={column} isCurrentUserPatient={isCurrentUserPatient} />
                    <span
                        className="case-list__cell-container"
                        role="button"
                        onKeyPress={redirectToCaseDetails}
                        onClick={redirectToCaseDetails}
                        tabIndex={0}
                    >
                        {patientFullName}
                    </span>
                </Table.Cell>
            );
        }
        case CaseListColumns.HOSPITAL_NUMBER: {
            const hospitalNo = getCasePatientData(patientData, "hospitalNo");
            const isMergedRecord = getIsMergedRecordField(patientData);
            const isDeceasedPatient = getIsDeceasedPatient(patientData);

            return (
                <Table.Cell
                    onClick={redirectToCaseDetails}
                    className={getHospitalNumberClassName(isDeceasedPatient, isMergedRecord)}
                >
                    {hospitalNo}
                    <div>
                        {isMergedRecord && <IntegrationPatientLabel type={PatientLabelTypes.MERGED_RECORD} />}
                        {isDeceasedPatient && <IntegrationPatientLabel type={PatientLabelTypes.DECEASED} />}
                    </div>
                </Table.Cell>
            );
        }
        case CaseListColumns.NHS: {
            const nhs = getCasePatientData(patientData, "nhs");

            return (
                <Table.Cell onClick={redirectToCaseDetails}>
                    <p className="case-list-nhs case-list__cell-container">{nhs}</p>
                </Table.Cell>
            );
        }
        case CaseListColumns.ORGANISATION: {
            return (
                <Table.Cell className="case-list__cell">
                    <CaseListTableHeaderItem columnName={column} isCurrentUserPatient={isCurrentUserPatient} />
                    <span
                        className="case-list__cell-container"
                        role="button"
                        onKeyPress={redirectToCaseDetails}
                        onClick={redirectToCaseDetails}
                        tabIndex={0}
                    >
                        {currentCase.organisationName}
                    </span>
                </Table.Cell>
            );
        }
        case CaseListColumns.LOCATION: {
            return <Table.Cell onClick={redirectToCaseDetails}>{currentCase.locationName}</Table.Cell>;
        }
        case CaseListColumns.BIRTH: {
            const birthField =
                currentCase?.patient?.patientData && getPatientField(currentCase.patient.patientData, "birth");
            const dateOfBirth = birthField && (getPatientData({ patientDataItem: birthField }) as string);
            const formattedBirthDate = dateOfBirth && formatTimeDOB(dateOfBirth);

            return (
                <Table.Cell className="case-list__cell">
                    <CaseListTableHeaderItem columnName={column} isCurrentUserPatient={isCurrentUserPatient} />
                    <span
                        className="case-list__cell-container"
                        role="button"
                        onKeyPress={redirectToCaseDetails}
                        onClick={redirectToCaseDetails}
                        tabIndex={0}
                    >
                        {formattedBirthDate}
                    </span>
                </Table.Cell>
            );
        }
        case CaseListColumns.DERMATOLOGIST: {
            const { assignmentDetails } = currentCase;
            const { assigneeName = "" } = { ...assignmentDetails };

            return <Table.Cell onClick={redirectToCaseDetails}>{assigneeName}</Table.Cell>;
        }
        case CaseListColumns.MANAGEMENT_OUTCOME: {
            const { recommendation } = currentCase;
            return <Table.Cell onClick={redirectToCaseDetails}>{recommendation}</Table.Cell>;
        }
        case CaseListColumns.SAS_LINK: {
            const { sasLink } = currentCase;

            const croppedLink = () => {
                if (sasLink === null) {
                    return undefined;
                }

                if (sasLink.includes("/") && sasLink.slice(-1) !== "/") {
                    return `/${sasLink.replace(/^(.*[\\/])/, "")}`;
                }

                return sasLink.length > 15
                    ? `...${sasLink.substring(sasLink.length - 10, sasLink.length - 1)}`
                    : sasLink;
            };

            return (
                <Table.Cell>
                    {sasLink?.length ? (
                        <a className="case-sas-link" href={sasLink} target="_blank" rel="noreferrer">
                            {croppedLink()}
                            <Icon name="share square" />
                        </a>
                    ) : null}
                </Table.Cell>
            );
        }
        case CaseListColumns.OVERDUE_FOR: {
            const { overdueCreationDate } = currentCase;
            const overdueTime = getDatetimeOverdueOrSinceUpdate(overdueCreationDate);

            return (
                <Table.Cell>
                    {overdueCreationDate ? (
                        <div className="overdue-time">
                            {singledDays(overdueTime)}
                            {singledHours(overdueTime)}
                            {singledMinutes(overdueTime)}
                        </div>
                    ) : null}
                </Table.Cell>
            );
        }
        case CaseListColumns.DATE_ENTERED_STATUS: {
            const { caseStatusModificationDate } = currentCase;

            return <Table.Cell>{moment(caseStatusModificationDate).format("D MMM YYYY, hh:mm A")}</Table.Cell>;
        }
        case CaseListColumns.REMOTE_TIMELINE: {
            if (!currentCase.remote) {
                return <Table.Cell />;
            }

            const currentStatus = getCaseStep(currentCase);

            return <Table.Cell>{RemoteTimelineDictionary[currentStatus]}</Table.Cell>;
        }
        case CaseListColumns.STATUS: {
            const { caseStatus, overdue, overdueCreationDate } = currentCase;
            const currentColor = COLORS_MAP[caseStatus];
            const textStatus = isCurrentUserPatient
                ? CaseStatusDictionaryPatient[caseStatus]
                : CaseStatusDictionary[caseStatus];
            const isMarkedAsOverdue = isCurrentUserPatient ? false : overdue;

            return (
                <Table.Cell className="case-list__cell">
                    <CaseListTableHeaderItem columnName={column} isCurrentUserPatient={isCurrentUserPatient} />
                    <CaseListLabel
                        aria-label={`View ${textStatus} case`}
                        onKeyDown={(e) => handleKeyDown(e, redirectToCaseDetails)}
                        onClick={redirectToCaseDetails}
                        text={textStatus}
                        color={currentColor || "black"}
                        basic={!currentColor}
                        overdue={isMarkedAsOverdue}
                        overdueCreationDate={overdueCreationDate}
                    />
                </Table.Cell>
            );
        }
        case CaseListColumns.ACTION: {
            return (
                <Table.Cell className="case-list__cell">
                    <CaseListTableHeaderItem columnName={column} isCurrentUserPatient={isCurrentUserPatient} />
                    <div className="case-list__cell-container">
                        <CaseListCellAction userRole={currentUserRole} currentCase={currentCase} />
                    </div>
                </Table.Cell>
            );
        }
        case CaseListColumns.MANAGEMENT_OUTCOME_CLIENT: {
            const clientManagementOutcome = getManagementOutcome(false);

            return <Table.Cell onClick={redirectToCaseDetails}>{clientManagementOutcome}</Table.Cell>;
        }
        case CaseListColumns.MANAGEMENT_OUTCOME_SA: {
            const saManagementOutcome = getManagementOutcome(true);

            return <Table.Cell onClick={redirectToCaseDetails}>{saManagementOutcome}</Table.Cell>;
        }
        case CaseListColumns.CREATOR: {
            const { createdByName } = currentCase;

            return <Table.Cell onClick={redirectToCaseDetails}>{createdByName}</Table.Cell>;
        }
        case CaseListColumns.CREATOR_EMAIL: {
            const { createdByEmail } = currentCase;

            return <Table.Cell onClick={redirectToCaseDetails}>{createdByEmail}</Table.Cell>;
        }
        case CaseListColumns.ORGANISATION_EMAIL: {
            const { organisationEmail } = currentCase;

            return <Table.Cell onClick={redirectToCaseDetails}>{organisationEmail}</Table.Cell>;
        }
        case CaseListColumns.PATHWAY: {
            const { nonSkinCancer, remote: isRemote } = currentCase;

            if (isRemote) {
                return <Table.Cell>Remote model</Table.Cell>;
            }

            return <Table.Cell>{nonSkinCancer ? "Other skin conditions" : "Skin cancer"}</Table.Cell>;
        }
        case CaseListColumns.DATE_ENTERED_LIST: {
            const { kitDeliveryStatusLastModificationDate } = currentCase;

            if (!kitDeliveryStatusLastModificationDate) {
                return <Table.Cell />;
            }

            const dateEnteredListCellContents = formatDatetimeDifference(kitDeliveryStatusLastModificationDate);

            return <Table.Cell>{dateEnteredListCellContents}</Table.Cell>;
        }
        case CaseListColumns.DEMOGRAPHIC_SOURCE: {
            const { patient, homeInitiated, remote } = currentCase;
            const { integrationInfo } = { ...patient };

            if (!patient) {
                return <Table.Cell />;
            }
            let demographicSourceText = "Clinician";
            if (isPASIntegration(integrationInfo?.integrationName)) {
                demographicSourceText = "UHB Oceano PAS";
            }
            if (integrationInfo?.integrationName === PatientDetailsIntegration.PDS) {
                demographicSourceText = "PDS";
            }
            if (homeInitiated || remote) {
                demographicSourceText = "Patient";
            }

            return <Table.Cell>{demographicSourceText}</Table.Cell>;
        }
        default: {
            return null;
        }
    }
};

CaseListTableCell.defaultProps = {
    openCaseInNewTab: () => undefined,
};

export default CaseListTableCell;
