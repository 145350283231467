import { useState } from "react";

import useInterval from "hooks/useInterval";

const COUNTDOWN_INITIAL = 60;
const COUNTDOWN_TIMEOUT = 1000;

interface IUseCountdownTimer {
    countdownTime: number;
    isCountdownFinished: boolean;
    resetCountdown: (resetCountValue?: number) => void;
}

const useCountdownTimer = (startCount = COUNTDOWN_INITIAL): IUseCountdownTimer => {
    const [countdownTime, setCountdownTime] = useState<number>(startCount);

    const isCountdownFinished = countdownTime <= 0;

    useInterval(
        () => {
            setCountdownTime(countdownTime - 1);
        },
        COUNTDOWN_TIMEOUT,
        isCountdownFinished
    );

    const resetCountdown = (resetCountValue = COUNTDOWN_INITIAL): void => {
        setCountdownTime(resetCountValue);
    };

    return {
        countdownTime,
        isCountdownFinished,
        resetCountdown,
    };
};

export default useCountdownTimer;
