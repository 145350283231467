import { FC, KeyboardEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import WhyMFAModal from "components/MFA/WhyMFAModal";

import { MFAButtons } from "model/mfa";

import * as actions from "redux/actions";
import getMFASetupState from "redux/selectors/mfa";

import userService from "services/userService";
import { homeScreenInitialisation } from "services/authorizationService";

import "scss/MFASetup.scss";

interface IInformation {
    handleNext: () => void;
}

const Information: FC<IInformation> = ({ handleNext }) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const mfaSetupState = useSelector(getMFASetupState);
    const { hasSeenMFAScreen, gracePeriodCountdownInDays } = mfaSetupState;
    const dispatch = useDispatch();

    useEffect(() => {
        const createGracePeriodStartDate = async () => {
            userService.createMFAGracePeriodStartDate();
        };
        if (!hasSeenMFAScreen) {
            createGracePeriodStartDate();
        }
    }, []);

    const handleStart = (): void => {
        handleNext();
    };

    const handleKeyDown = (e: KeyboardEvent, handleFunction: () => void): void => {
        if (e.key === "Enter") {
            handleFunction();
        }
    };

    const handleSkip = async (): Promise<void> => {
        dispatch(actions.mfaActions.mfaCompleteSetup({ mfaActivated: false }));
        await homeScreenInitialisation(dispatch);
    };

    const handleOpenModal = (): void => setShowModal(true);

    return (
        <>
            <Box>
                <Box display="flex" alignContent="center">
                    <img className="mfa-setup-icon" alt="MFA setup icon" src="/images/MFASetupInfo.png" />
                </Box>
                <Box>
                    <h2>Keep your account secure with Multi Factor Authentication (MFA)</h2>
                    <Box>
                        <p>
                            Your organisation requires you to set up an additional layer of security when signing into
                            your Skin Analytics account, so we are going to guide you on how to set up the multi factor
                            authentication.
                        </p>
                        <p>Have your email inbox and mobile phone handy to complete the setup.</p>
                    </Box>

                    <Box display="flex" alignItems="center" flexDirection="column" paddingTop={2}>
                        <Box
                            className="text-button"
                            role="button"
                            tabIndex={0}
                            onClick={handleOpenModal}
                            onKeyDown={(e) => handleKeyDown(e, handleOpenModal)}
                        >
                            Why am I being asked to set up MFA?
                        </Box>
                        <Box marginBottom={2} marginTop={2} width="100%">
                            <Button
                                className="full-width"
                                variant="contained"
                                color="primary"
                                onClick={handleStart}
                                onKeyDown={(e) => handleKeyDown(e, handleStart)}
                            >
                                {MFAButtons.START_SETUP}
                            </Button>
                        </Box>
                        {gracePeriodCountdownInDays > 0 ? (
                            <Box
                                className="text-button--large text-button__heavy text-button__heavy--blue"
                                tabIndex={0}
                                role="button"
                                onKeyDown={(e) => handleKeyDown(e, handleSkip)}
                                onClick={handleSkip}
                            >
                                {`Skip for now (${gracePeriodCountdownInDays} days until this is required)`}
                            </Box>
                        ) : null}
                    </Box>
                </Box>
            </Box>
            <WhyMFAModal showModal={showModal} handleClose={() => setShowModal(false)} />
        </>
    );
};

export default Information;
