import {
    IMFASetupState,
    MFAInitialScreen,
    MFASetupStatus,
    MFAVerificationChannel,
    TrustedDeviceStatus,
} from "model/mfa";

import types from "redux/actions/types";

const initialState: IMFASetupState = {
    step: 0,
    daysUntilTrustedDeviceExpiry: null,
    hasSeenMFAScreen: false,
    initialScreen: MFAInitialScreen.NO_MFA,
    trustedDeviceStatus: TrustedDeviceStatus.NOT_TRUSTED,
    gracePeriodCountdownInDays: null,
    isMFAActivated: false,
    email: {
        status: MFASetupStatus.NOT_VERIFIED,
        verificationDate: null,
        destination: null,
        codeCreationTime: null,
    },
    sms: {
        status: MFASetupStatus.NOT_VERIFIED,
        verificationDate: null,
        destination: null,
        codeCreationTime: null,
    },
    isEnabledForAnyOrganisation: false,
    trustedDeviceUuid: null,
    isMobileNumberReverificationRequired: false,
};

const getInitialScreen = (state: any, isMobileNumberReverificationRequired: boolean): MFAInitialScreen => {
    const { enabled, isMFAActivated, trustedDeviceStatus } = state;
    if (!enabled) {
        return MFAInitialScreen.NO_MFA;
    }
    if (trustedDeviceStatus === TrustedDeviceStatus.TRUSTED) {
        return isMobileNumberReverificationRequired ? MFAInitialScreen.REVERIFY_MOBILE_NUMBER : MFAInitialScreen.NO_MFA;
    }
    if (trustedDeviceStatus === TrustedDeviceStatus.ABOUT_TO_EXPIRE) {
        return MFAInitialScreen.TRUST_DEVICE_REINITIALISATION;
    }
    if (isMFAActivated) {
        return MFAInitialScreen.AUTHENTICATION;
    }

    return MFAInitialScreen.SETUP;
};

export default (state: IMFASetupState = initialState, action: any): IMFASetupState => {
    switch (action.type) {
        case types.MFA_STATUS_REQUEST_SUCCESS: {
            /**
             * Show MFA setup screen if:
             *
             *      1) One or more organisations has mfaEnabled true
             *      AND
             *      2) Either email or SMS channels has NOT been verified
             *      3) Either email or SMS channels have not been verified in last 180 days
             */
            const {
                hasSeenMFAScreen,
                gracePeriodCountdownInDays,
                email,
                sms,
                sms: { isMobileNumberReverificationRequired },
                isMFAActivated,
                daysUntilTrustedDeviceExpiry,
                trustedDeviceStatus,
                trustedDeviceUuid,
                enabled,
            } = action.payload;

            const initialScreen = getInitialScreen(action.payload, isMobileNumberReverificationRequired);

            return {
                ...state,
                step: 0,
                initialScreen,
                daysUntilTrustedDeviceExpiry,
                trustedDeviceStatus,
                hasSeenMFAScreen,
                gracePeriodCountdownInDays,
                isMFAActivated,
                [MFAVerificationChannel.EMAIL]: { ...email },
                [MFAVerificationChannel.SMS]: { ...sms },
                isEnabledForAnyOrganisation: enabled,
                trustedDeviceUuid,
                isMobileNumberReverificationRequired,
            };
        }
        case types.MFA_STATUS_REQUEST_ERROR: {
            return {
                ...state,
            };
        }
        case types.MFA_MOBILE_NUMBER_UPDATE: {
            return {
                ...state,
                [MFAVerificationChannel.SMS]: {
                    ...state[MFAVerificationChannel.SMS],
                    destination: action.payload,
                },
            };
        }
        case types.MFA_VERIFICATION_SENT: {
            return {
                ...state,
                [action.payload.channel]: {
                    ...state[action.payload.channel],
                    status: MFASetupStatus.PENDING,
                    destination:
                        action.payload.channel === MFAVerificationChannel.SMS
                            ? action.payload.destination
                            : state[action.payload.channel].destination,
                    codeCreationTime: action.payload.codeCreationTime,
                },
            };
        }
        case types.MFA_VERIFIED: {
            return {
                ...state,
                isMobileNumberReverificationRequired:
                    action.payload.channel === MFAVerificationChannel.SMS
                        ? false
                        : state.isMobileNumberReverificationRequired,
                [action.payload.channel]: {
                    ...state[action.payload.channel],
                    status: MFASetupStatus.VERIFIED,
                    codeCreationTime: action.payload.codeCreationTime,
                    verificationDate: action.payload.verificationDate,
                },
            };
        }
        case types.MFA_COMPLETE_SETUP: {
            return {
                ...state,
                isMFAActivated: action.payload.mfaActivated,
            };
        }
        case types.CLEAR_DATA: {
            return {
                ...initialState,
            };
        }
        case types.INCREMENT_STEP: {
            return {
                ...state,
                step: state.step + 1,
            };
        }
        case types.DECREMENT_STEP: {
            return {
                ...state,
                step: state.step - 1,
            };
        }
        case types.INIT_MOBILE_NUMBER_CHANGE: {
            return {
                ...state,
                step: 2,
            };
        }
        case types.CREATE_TRUSTED_DEVICE: {
            return {
                ...state,
                trustedDeviceUuid: action.payload.trustedDeviceUuid,
            };
        }
        case types.INIT_MOBILE_NUMBER_REVERIFICATION: {
            return {
                ...state,
                step: 2,
            };
        }
        default: {
            return state;
        }
    }
};
