import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DialogContent } from "@material-ui/core";

import ModalDialog from "components/templates/dialog/ModalDialog";
import * as authorizationService from "services/authorizationService";
import { useDispatch } from "react-redux";

dayjs.extend(customParseFormat);

interface IMaintenanceStartingModal {
    isPublic: boolean;
}

interface IMaintenanceData {
    _comment: string;
    isMaintenancePlanned: string;
    maintenanceDate: string;
    startTime: string;
    endTime: string;
}

const MaintenanceStartingModal = ({ isPublic }: IMaintenanceStartingModal) => {
    const [maintenanceData, setMaintenanceData] = useState<IMaintenanceData | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [startsInFive, setStartsInFive] = useState<boolean>(false);

    const dispatch = useDispatch();

    const { maintenanceDate: date, startTime, endTime, isMaintenancePlanned } = maintenanceData || {};

    // Fetch maintenance data when the component first loads - fetch required as file is outside or src folder
    // UseEffect will fetch maintenance data when component first mounts (i.e when app is first loaded as high level)
    // plus when 'isPublic' prop changes - going from public page (login) to home page
    useEffect(() => {
        fetch("/maintenance.json")
            .then((response) => response.json())
            .then((data) => {
                if (data.isMaintenancePlanned) {
                    setMaintenanceData(data);
                }
            })
            .catch((error) => console.error("Error fetching maintenance data:", error));
    }, [isPublic]);

    // Set up a periodic check every 5 minutes after data is fetched and decide if to render modal
    useEffect(() => {
        if (!maintenanceData || !isMaintenancePlanned) {
            return null;
        }
        const now = dayjs();
        const startDateTime = dayjs(`${date} ${startTime}`, "DD/MM/YYYY HH:mm");
        const endDateTime = dayjs(`${date} ${endTime}`, "DD/MM/YYYY HH:mm");

        const isToday = now.isSame(startDateTime, "day");
        const isPastEndTime = now.isAfter(endDateTime);

        if (!isToday || isPastEndTime) {
            return null;
        }

        // if maintenance is due to start within 15 minutes of current time, then show modal
        const checkMaintenance = (): void => {
            const todayNow = dayjs();
            const minsTillMaintenanceStarts = startDateTime.isAfter(todayNow) && startDateTime.diff(todayNow, "minute");
            const isInMaintenanceWindow =
                startDateTime.isBefore(todayNow, "hour") && todayNow.isBefore(endDateTime, "hour");
            if (isInMaintenanceWindow || minsTillMaintenanceStarts < 5) {
                setStartsInFive(true);
                setShowModal(true);
            } else if (minsTillMaintenanceStarts < 15) {
                setShowModal(true);
            } else {
                setShowModal(false);
            }
        };

        // Check on component mount and every 5 minutes thereafter
        checkMaintenance();
        const intervalId = setInterval(checkMaintenance, 5 * 60 * 1000);

        // Clean up the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, [maintenanceData]);

    if (!isMaintenancePlanned || isPublic || !showModal) return null;

    const handleLogout = async () => {
        try {
            await dispatch(authorizationService.logout());
        } catch (error) {
            console.error("Logout failed:", error);
        }
    };

    const logUserOut = () => {
        setShowModal(false);
        handleLogout();
    };

    const forcedLogoutTime = dayjs(startTime, "HH:mm").subtract(5, "minutes").format("HH:mm");

    return (
        <ModalDialog
            title="Routine maintenance starting shortly"
            open={showModal}
            onClose={(closeModal) => setShowModal(!closeModal)}
            maxWidth="md"
            disableBackdropClick
            buttons={[
                {
                    onClick: logUserOut,
                    text: "Logout",
                    colour: "primary",
                },
                !startsInFive
                    ? {
                          onClick: () => setShowModal(false),
                          text: "Continue",
                          colour: "primary",
                      }
                    : {},
            ]}
        >
            <DialogContent>
                <b>{`The platform will be unavailable between ${startTime} and ${endTime} GMT. Please ${
                    startsInFive ? "" : "finish what you are doing and"
                } log out.`}</b>
                <br />
                <br />
                <p>{`${
                    !startsInFive
                        ? `At ${forcedLogoutTime} you will be forced to logout and any unsaved changes will be lost.`
                        : ""
                }`}</p>
            </DialogContent>
        </ModalDialog>
    );
};

export default MaintenanceStartingModal;
