import { FC } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InfoOutlineIcon from "@material-ui/icons/InfoOutlined";
import clsx from "clsx";

import { SUPPORT_EMAIL } from "model/common";

import "scss/CustomMessage.scss";

export enum CustomMessageType {
    INFO = "info",
    ERROR = "error",
}

export type CustomMessageDetails = Omit<ICustomMessage, "type">;

export interface ICustomMessage {
    message: string;
    showDefaultHelp?: boolean;
    type: CustomMessageType;
    cta?: string;
    buttonText?: string;
    buttonHandler?: () => void;
}

const CustomMessage: FC<ICustomMessage> = ({ cta, message, showDefaultHelp, type, buttonText, buttonHandler }) => (
    <Box style={{ position: "relative" }} className={clsx("custom-message", type)}>
        {type === CustomMessageType.ERROR ? (
            <ErrorOutlineIcon fontSize="large" />
        ) : (
            <InfoOutlineIcon fontSize="medium" />
        )}
        <Button onClick={buttonHandler} style={{ position: "absolute", top: "0", right: "0", margin: "0" }}>
            {buttonText}
        </Button>
        <Box marginLeft={2}>
            <Box
                className={clsx("message-title", !cta && "message-title__only")}
                role="alert"
                aria-live="polite"
                aria-label={`${message} ${cta || ""}`}
            >
                <p style={{ whiteSpace: "pre-wrap" }}>{message}</p>
            </Box>
            <Box>
                {cta ? <p>{cta}</p> : null}
                {showDefaultHelp ? (
                    <p>
                        If you continue having trouble completing the process, contact our support team at:&nbsp;
                        <a href={`mailto:${SUPPORT_EMAIL}`} aria-label={`Send email to ${SUPPORT_EMAIL}`}>
                            {SUPPORT_EMAIL}.
                        </a>
                    </p>
                ) : null}
            </Box>
        </Box>
    </Box>
);

CustomMessage.defaultProps = {
    showDefaultHelp: false,
    cta: null,
};

export default CustomMessage;
