import { Component, ReactNode } from "react";
import { Redirect } from "react-router-dom";
import { Button, Divider, Form, Grid, Segment } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";
import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import clsx from "clsx";

import ActivateModal from "components/Administration/UserManagement/ActivateModal";
import CustomRichTextEditor from "components/templates/Editor";
import LoadingSpinner from "components/templates/LoadingSpinner";

import getAvailability from "helpers/availability";
import createDropdownOptions from "helpers/semanticUi";
import removeNullPropertiesFromRequest from "helpers/requestUtils";

import {
    DermatologistAvailabilityMapping,
    DermatologistAvailabilityLabel,
    DermatologistAvailabilityEntities,
} from "model/administrationPages";
import { IDictionaryItem } from "model/dictionaryItem";
import { IFormError } from "model/formError";
import HttpStatus from "model/httpStatus";
import { IUser, IUserData } from "model/user";
import { UserRole } from "model/userRole";
import { UserStatus } from "model/userStatus";
import UserTitle from "model/userTitle";
import ViewEditMode from "model/viewEditMode";

import organisationService from "services/organizationService";
import userService from "services/userService";

const AVAILABILITY_FIELD_TEXT = "availabilityField";

interface IUserDetailsScreenProps {
    params: { uuid: string };
}

interface IUserDetailsScreenState {
    errorDetails: Partial<IFormError>[];
    mode: ViewEditMode;
    organisationDict: IDictionaryItem[];
    submitting: boolean;
    activeUser: boolean;
    user: IUser;
    emailField: string;
    alternativeEmailField: string;
    nameField: string;
    surnameField: string;
    roleField: UserRole | undefined;
    organisationField: string[];
    showModal: boolean;
    titleField?: string;
    phoneNumberField?: string;
    availabilityField?: string;
    gmcNumberField?: string;
    additionalRoleInfoField?: string;
    notesField?: string;
    linkedUserEmailField?: string;
}

class UserDetailsScreen extends Component<IUserDetailsScreenProps, IUserDetailsScreenState> {
    constructor(props: IUserDetailsScreenProps) {
        super(props);
        this.state = {
            errorDetails: [],
            mode: ViewEditMode.VIEW,
            organisationDict: [],
            activeUser: true,
            availabilityField: DermatologistAvailabilityEntities.AVAILABLE_FOR_CASE_ALLOCATION,
            emailField: "",
            alternativeEmailField: "",
            gmcNumberField: "",
            nameField: "",
            organisationField: [],
            phoneNumberField: "",
            roleField: undefined,
            submitting: false,
            surnameField: "",
            titleField: "",
            additionalRoleInfoField: "",
            notesField: "",
            linkedUserEmailField: "",
            user: null,
            showModal: false,
        };
        this.toggleUser = this.toggleUser.bind(this);
    }

    public componentDidMount() {
        organisationService.getAllOrganisationsDictionary().then((result) => {
            const withAllRemoved = result?.filter((res) => res.key !== "ALL");
            this.setState({ organisationDict: withAllRemoved });
        });
        this.fetchUser();
    }

    private getEmailField(): ReactNode {
        const { mode, user, emailField, errorDetails } = this.state;
        const error = errorDetails.find(
            (err) => err.path === ".body.email" || (err.message.includes("email") && err.path !== "alternativeEmail")
        );
        if (mode === ViewEditMode.VIEW) {
            return (
                <>
                    <p>
                        <b>Email</b>
                    </p>
                    <p>{user.email}</p>
                </>
            );
        }
        return (
            <Form.Input
                error={error ? error.message : false}
                label="Email"
                type="text"
                required
                id="emailField"
                onChange={this.onFieldChange}
                value={emailField}
            />
        );
    }

    private getAlternativeEmail(): ReactNode {
        const { mode, user, alternativeEmailField, errorDetails } = this.state;

        const error = errorDetails.find(
            (err) =>
                err.path === ".body.alternativeEmail" ||
                err.path === "alternativeEmail" ||
                err.message.includes("alternativeEmail")
        );
        if (mode === ViewEditMode.VIEW) {
            return (
                <>
                    <p>
                        <b>Alternative email</b>
                    </p>
                    <p>{user.alternativeEmail}</p>
                </>
            );
        }
        return (
            <Form.Input
                error={error ? error.message : false}
                label="AlternativeEmail"
                type="text"
                id="alternativeEmailField"
                onChange={this.onFieldChange}
                value={alternativeEmailField}
            />
        );
    }

    private getNameField(): ReactNode {
        const { mode, user, nameField, errorDetails } = this.state;

        const error = errorDetails.find((err) => err.path === ".body.name");

        if (mode === ViewEditMode.VIEW) {
            return (
                <>
                    <p>
                        <b>Name</b>
                    </p>
                    <p>{user.name}</p>
                </>
            );
        }

        return (
            <Form.Input
                error={error ? error.message : false}
                label="Name"
                type="text"
                id="nameField"
                required
                onChange={this.onFieldChange}
                value={nameField}
            />
        );
    }

    private getSurnameField(): ReactNode {
        const { mode, user, surnameField, errorDetails } = this.state;

        const error = errorDetails.find((err) => err.path === ".body.surname");

        if (mode === ViewEditMode.VIEW) {
            return (
                <>
                    <p>
                        <b>Surname</b>
                    </p>
                    <p>{user.surname}</p>
                </>
            );
        }
        return (
            <Form.Input
                error={error ? error.message : false}
                label="Surname"
                type="text"
                id="surnameField"
                required
                onChange={this.onFieldChange}
                value={surnameField}
            />
        );
    }

    private getRoleField(): ReactNode {
        const { mode, roleField, user } = this.state;

        if (mode === ViewEditMode.VIEW) {
            return (
                <>
                    <p>
                        <b>Role</b>
                    </p>
                    <p>{user.role}</p>
                </>
            );
        }
        return (
            <Form.Select
                label="Role"
                id="roleField"
                placeholder="Select user role"
                required
                onChange={this.onFieldChange}
                value={roleField}
                className="user-details-select"
                options={Object.keys(UserRole).map((key) => ({
                    key,
                    text: UserRole[key],
                    value: key,
                }))}
            />
        );
    }

    private getAdditionalRoleInfoField(): ReactNode {
        const { user, additionalRoleInfoField, mode } = this.state;
        const { additionalRoleInfo } = user;

        if (mode === ViewEditMode.VIEW) {
            return (
                <>
                    <p>
                        <b>Additional role info</b>
                    </p>
                    <p>{additionalRoleInfo}</p>
                </>
            );
        }

        return (
            <Form.Input
                label="Additional role info"
                type="text"
                id="additionalRoleInfoField"
                onChange={this.onFieldChange}
                value={additionalRoleInfoField}
            />
        );
    }

    private getPhoneNumberField(): ReactNode {
        const { user, phoneNumberField, mode, errorDetails } = this.state;

        const error = errorDetails.find((err) => err.path === ".body.mobileNumber" || err.path === "phoneNumber");

        if (mode === ViewEditMode.VIEW) {
            return (
                <>
                    <p>
                        <b>Phone number</b>
                    </p>
                    <p>{user.mobileNumber}</p>
                </>
            );
        }
        return (
            <Form.Input
                error={error ? error.message : false}
                label={
                    <span>
                        Phone number{" "}
                        <Tooltip title="Phone number should include a country code and without the leading '0' of the number, e.g., +44 7768913487 for the UK.">
                            <InfoOutlinedIcon fontSize="small" />
                        </Tooltip>
                    </span>
                }
                type="text"
                id="phoneNumberField"
                required={this.showCallbackAgentOrDermatologistFields()}
                onChange={this.onFieldChange}
                value={phoneNumberField}
            />
        );
    }

    private getAvailabilityField(): ReactNode {
        const { availabilityField, mode, errorDetails } = this.state;
        const availability = DermatologistAvailabilityLabel[availabilityField];

        const error = errorDetails.find((err) => err.path === ".body.availability");

        if (mode === ViewEditMode.VIEW) {
            return (
                <>
                    <p>
                        <b>Availability for case allocation</b>
                    </p>
                    <p>{availability}</p>
                </>
            );
        }
        return (
            <Form.Dropdown
                aria-label="Availability for case allocation"
                id={AVAILABILITY_FIELD_TEXT}
                error={error ? error.message : false}
                label="Availability for case allocation"
                fluid
                onChange={this.onFieldChange}
                selection
                required
                options={createDropdownOptions(DermatologistAvailabilityLabel)}
                value={availabilityField || DermatologistAvailabilityEntities.AVAILABLE_FOR_CASE_ALLOCATION}
            />
        );
    }

    private getGmcNumberField(): ReactNode {
        const { user, gmcNumberField, mode, errorDetails } = this.state;

        const error = errorDetails.find((err) => err.path === ".body.gmcNumber");

        if (mode === ViewEditMode.VIEW) {
            return (
                <>
                    <p>
                        <b>GMC number</b>
                    </p>
                    <p>{user.gmcNumber}</p>
                </>
            );
        }
        return (
            <Form.Input
                error={error ? error.message : false}
                label="GMC number"
                type="text"
                id="gmcNumberField"
                required
                onChange={this.onFieldChange}
                value={gmcNumberField}
            />
        );
    }

    private getNotesField(): ReactNode {
        const { mode, user, notesField } = this.state;
        const { notes } = user;
        if (mode === ViewEditMode.VIEW) {
            return (
                <div>
                    <p>
                        <b>Notes</b>
                    </p>
                    <Segment>
                        <div>{ReactHtmlParser(notes)}</div>
                    </Segment>
                </div>
            );
        }
        return (
            <div>
                <div className="field">
                    <span>Notes</span>
                </div>
                <CustomRichTextEditor value={notesField} onChange={this.onRichTextChange} name="notesField" />
            </div>
        );
    }

    private getSubmitButton(): ReactNode {
        const { mode, submitting } = this.state;

        if (mode === ViewEditMode.EDIT) {
            return (
                <Grid.Row>
                    <Grid.Column width={9}>
                        <Button loading={submitting} floated="right" type="submit">
                            Update
                        </Button>
                        <Button loading={submitting} floated="right" onClick={this.cancel}>
                            Cancel
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            );
        }
        return <></>;
    }

    private getLastLoginDate(): ReactNode {
        const { user } = this.state;

        return (
            <>
                <p>
                    <b>Last Login</b>
                </p>
                <p>{user.lastLogin ? new Date(user.lastLogin).toLocaleString() : ""}</p>
            </>
        );
    }

    private getLoginCount(): ReactNode {
        const { user } = this.state;

        return (
            <>
                <p>
                    <b>Login Count</b>
                </p>
                <p>{user.loginCount || ""}</p>
            </>
        );
    }

    private getTitle(): ReactNode {
        const { mode, user, titleField } = this.state;

        if (mode === ViewEditMode.VIEW) {
            return (
                <>
                    <p>
                        <b>Title</b>
                    </p>
                    <p>{user.title || ""}</p>
                </>
            );
        }

        const options = Object.keys(UserTitle).map((key) => ({
            key,
            text: UserTitle[key],
            value: UserTitle[key],
        }));

        return (
            <Form.Select
                label="Title"
                id="titleField"
                placeholder="Select user title"
                onChange={this.onFieldChange}
                value={titleField}
                options={options}
            />
        );
    }

    private getOrganisationField(): ReactNode {
        const { mode, organisationDict, organisationField } = this.state;

        if (mode === ViewEditMode.VIEW) {
            const organisations: IDictionaryItem[] = organisationDict.length ? [] : undefined;
            if (organisations) {
                organisationField.forEach((orgUuid) => {
                    const orgDictItem = organisationDict.find((dictItem) => dictItem.key === orgUuid);
                    if (orgDictItem) {
                        organisations.push(orgDictItem);
                    }
                });
            }

            const getOrganisationNames = (): ReactNode => {
                if (!organisations) {
                    return <span>Loading...</span>;
                }
                return organisations.map((org) => (
                    <span key={org.key} className="organisation-name-chip">
                        {org.text}
                    </span>
                ));
            };

            return (
                <>
                    <p>
                        <b>Organisation</b>
                    </p>
                    <p>{getOrganisationNames()}</p>
                </>
            );
        }

        return (
            <Form.Dropdown
                label="Organisation"
                type="text"
                id="organisationField"
                placeholder="Select organisation"
                className="user-details-select"
                required
                multiple
                search
                selection
                onChange={this.onFieldChange}
                value={organisationField}
                options={organisationDict.map((org: IDictionaryItem, idx: number) => ({
                    text: org.text,
                    key: idx,
                    value: org.value,
                }))}
            />
        );
    }

    private getLinkedUserField(): ReactNode {
        const { mode, errorDetails, user, linkedUserEmailField } = this.state;

        const error = errorDetails.find((err) => err.path === ".body.linkedUserEmail");

        return (
            <>
                <p className="link-user-account-label">Merge another (secondary) account into this account</p>
                <p className={clsx("link-user-account-sublabel", mode === ViewEditMode.EDIT && "edit-mode")}>
                    To merge a secondary account into this account, add below the email address of the secondary account
                    once that account had been deactivated. Historic cases will be transferred from the secondary
                    account to the primary.
                </p>
                {mode === ViewEditMode.VIEW ? (
                    <p>
                        {user.linkedUser?.linkedUserEmail && !user.linkedUser?.removed
                            ? user.linkedUser?.linkedUserEmail
                            : ""}
                    </p>
                ) : (
                    <Form.Input
                        error={error ? error.message : false}
                        placeholder="user_secondary_account@mail.com"
                        type="text"
                        id="linkedUserEmailField"
                        value={linkedUserEmailField}
                        onChange={this.onFieldChange}
                    />
                )}
            </>
        );
    }

    public fetchUser = (): void => {
        const { params } = this.props;
        const { availabilityField } = this.state;

        userService.getUserByUuid(params.uuid).then((result) => {
            const { data } = result;
            const {
                status,
                availability,
                email,
                alternativeEmail,
                gmcNumber,
                name,
                organisationUuid,
                additionalOrganisationData,
                mobileNumber,
                role,
                surname,
                title,
                additionalRoleInfo,
                notes,
                linkedUser,
            } = data;
            const isActiveUser = status === UserStatus.ACTIVE;

            const additionalOrganisationUuids = additionalOrganisationData
                ? additionalOrganisationData.map((orgData) => orgData.organisationUuid)
                : [];

            const orgUuids = [organisationUuid, ...additionalOrganisationUuids];

            const linkedUserEmail =
                linkedUser?.linkedUserEmail && !linkedUser?.removed ? linkedUser?.linkedUserEmail : "";

            this.setState({
                activeUser: isActiveUser,
                availabilityField: getAvailability(availability) || availabilityField,
                emailField: email,
                alternativeEmailField: alternativeEmail,
                gmcNumberField: gmcNumber,
                nameField: name,
                organisationField: orgUuids,
                phoneNumberField: mobileNumber,
                roleField: role,
                submitting: false,
                surnameField: surname,
                titleField: title,
                additionalRoleInfoField: additionalRoleInfo,
                notesField: notes,
                linkedUserEmailField: linkedUserEmail,
                user: data,
                mode: ViewEditMode.VIEW,
            });

            if (data.linkedUser && !data.linkedUser.removed) {
                userService.getUserByUuid(data.linkedUser.linkedUserUuid).then((response) => {
                    this.setState({ linkedUserEmailField: response.data.email });
                });
            }
        });
    };

    private onModeChangeClick = (): void => {
        const { mode } = this.state;

        if (mode === ViewEditMode.VIEW) {
            this.setState({ mode: ViewEditMode.EDIT });
        } else {
            const {
                user: {
                    availability,
                    email,
                    alternativeEmail,
                    gmcNumber,
                    name,
                    organisationUuid,
                    additionalOrganisationData,
                    mobileNumber,
                    role,
                    surname,
                    title,
                    linkedUser,
                },
            } = this.state;

            const additionalOrganisationUuids = additionalOrganisationData
                ? additionalOrganisationData.map((orgData) => orgData.organisationUuid)
                : [];
            const orgUuids = [organisationUuid, ...additionalOrganisationUuids];

            const linkedUserEmail =
                linkedUser?.linkedUserEmail && !linkedUser?.removed ? linkedUser?.linkedUserEmail : "";

            this.setState({
                mode: ViewEditMode.VIEW,
                availabilityField: getAvailability(availability),
                emailField: email,
                alternativeEmailField: alternativeEmail,
                errorDetails: [],
                gmcNumberField: gmcNumber,
                nameField: name,
                organisationField: orgUuids,
                phoneNumberField: mobileNumber,
                roleField: role,
                surnameField: surname,
                titleField: title,
                linkedUserEmailField: linkedUserEmail,
            });
        }
    };

    private onFieldChange = (event: any, obj: any): void => {
        const stateObj = {};
        const fieldName = obj.id;
        const fieldValue = obj.value;

        stateObj[fieldName] = fieldValue;

        this.setState(stateObj);
    };

    private onRichTextChange = (value: string, name: string): void => {
        const previousState = this.state;
        this.setState({ ...previousState, [name]: value });
    };

    private cancel = (): void => {
        this.onModeChangeClick();
    };

    private submit = (): void => {
        this.setState({ errorDetails: [] });

        if (this.validateSubmit()) {
            this.setState({ submitting: true });
            const {
                emailField,
                alternativeEmailField,
                nameField,
                organisationField,
                roleField,
                surnameField,
                titleField,
                availabilityField,
                gmcNumberField,
                phoneNumberField,
                additionalRoleInfoField,
                notesField,
                linkedUserEmailField,
                user: { uuid },
            } = this.state;

            const withAdditionalData = this.showCallbackAgentOrDermatologistFields();
            const withLinkedUserData = this.showLinkedUserField();

            const data = {
                email: emailField,
                alternativeEmail: alternativeEmailField,
                name: nameField,
                organisationUuids: organisationField,
                role: roleField,
                surname: surnameField,
                title: titleField,
                mobileNumber: phoneNumberField,
                ...(withAdditionalData
                    ? {
                          availability: DermatologistAvailabilityMapping[availabilityField],
                          gmcNumber: gmcNumberField,
                      }
                    : {}),
                additionalRoleInfo: additionalRoleInfoField,
                notes: notesField,
                ...(withLinkedUserData ? { linkedUserEmail: linkedUserEmailField } : {}),
            };

            const updatedRequestData = removeNullPropertiesFromRequest<IUserData>(data);

            userService
                .updateUser(uuid, updatedRequestData)
                .then((result) => {
                    this.setState({ submitting: false });
                    if (result.status === HttpStatus.OK) {
                        const { user, organisationDict } = this.state;
                        const [primaryOrg, ...additionalOrgUuids] = organisationField;
                        const primaryOrgName = organisationDict.find((item) => item.key === primaryOrg).text;

                        const additionalOrganisationData =
                            additionalOrgUuids.length > 0
                                ? additionalOrgUuids.map((orgUuid) => {
                                      const orgName = organisationDict.find((item) => item.key === orgUuid).text;
                                      return { organisationName: orgName, organisationUuid: orgUuid };
                                  })
                                : [];

                        const isLinkedUserFieldPopulated = !!linkedUserEmailField;

                        const updatedUser: IUser = {
                            ...user,
                            email: emailField,
                            alternativeEmail: alternativeEmailField,
                            name: nameField,
                            role: roleField,
                            surname: surnameField,
                            title: titleField,
                            organisationUuid: primaryOrg,
                            organisationName: primaryOrgName,
                            additionalOrganisationData,
                            mobileNumber: phoneNumberField,
                            ...(withAdditionalData
                                ? {
                                      availability: DermatologistAvailabilityMapping[availabilityField],
                                      gmcNumber: gmcNumberField,
                                  }
                                : {}),
                            additionalRoleInfo: additionalRoleInfoField,
                            notes: notesField,
                            ...(withLinkedUserData
                                ? {
                                      linkedUser: {
                                          linkedUserEmail: linkedUserEmailField,
                                          linkedUserUuid: undefined,
                                          linkedUserOrganisationName: undefined,
                                          linkedUserOrganisationUuid: undefined,
                                          removed: !isLinkedUserFieldPopulated,
                                      },
                                  }
                                : {}),
                        };

                        this.setState({
                            user: updatedUser,
                            mode: ViewEditMode.VIEW,
                        });
                    }
                })
                .catch((err) => {
                    this.setState({
                        errorDetails: err.response.data.errors,
                        submitting: false,
                    });
                });
        } else {
            this.setState({ submitting: false });
        }
    };

    private onActivateDeactivateUser = (): void => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    private showCallbackAgentOrDermatologistFields(): boolean {
        const { roleField } = this.state;

        const isCallbackAgentUser = roleField === UserRole.CALLBACK_AGENT;
        const isDermatologist = roleField === UserRole.DERMATOLOGIST;

        return isCallbackAgentUser || isDermatologist;
    }

    private showLinkedUserField(): boolean {
        const { roleField } = this.state;
        return roleField === UserRole.DERMATOLOGIST;
    }

    private toggleUser(): void {
        this.setState({ submitting: true });

        const {
            activeUser,
            user: { uuid },
        } = this.state;

        userService
            .toggleUser(uuid)
            .then((result) => {
                this.setState({ showModal: false, submitting: false });
                if (result.status === HttpStatus.OK) {
                    this.setState({
                        activeUser: !activeUser,
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    errorDetails: err?.response?.data?.errors,
                    submitting: false,
                });
            });
    }

    /**
     * Inline validation for the surname field.
     * The OpenAPI definition marks surname as a required field, but with a minLength of 0.
     * This is because we have two use cases where surname can be empty:
     *  1) Creating a system user (for developer purposes)
     *  2) Registration flow - can create an account with an email address, surname is not required
     * @returns boolean
     */
    private validateSurnameLength(): boolean {
        const { surnameField } = this.state;

        if (!surnameField || surnameField?.trim()?.length === 0) {
            return false;
        }

        return true;
    }

    private validateGmcNumberField(): boolean {
        const { gmcNumberField } = this.state;

        if (!gmcNumberField || gmcNumberField?.trim()?.length === 0) {
            return false;
        }

        return true;
    }

    private validatePhoneNumberField(): boolean {
        const { phoneNumberField } = this.state;

        if (!phoneNumberField || phoneNumberField?.trim()?.length === 0) {
            return false;
        }

        return true;
    }

    private validateAlternativeEmail(): boolean {
        const { alternativeEmailField, emailField } = this.state;
        const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (alternativeEmailField === emailField) {
            this.setState({
                errorDetails: [
                    {
                        path: "alternativeEmail",
                        message: "Alternative email should not be the same as the primary email address",
                    },
                ],
            });
            return false;
        }

        if (alternativeEmailField && !EMAIL_REGEX.test(alternativeEmailField)) {
            this.setState({
                errorDetails: [
                    {
                        path: "alternativeEmail",
                        message: "Provide a valid alternative email address",
                    },
                ],
            });
            return false;
        }
        return true;
    }

    private validateAvailability(): boolean {
        const { availabilityField } = this.state;
        const availabilityFieldAsNumber = Number(availabilityField);

        if (typeof availabilityFieldAsNumber !== "number") {
            return false;
        }

        return true;
    }

    private validateSubmit(): boolean {
        const { organisationField } = this.state;

        this.setState({
            errorDetails: [],
        });

        if (organisationField?.length === 0) {
            return false;
        }

        const additionalFieldValidation = this.showCallbackAgentOrDermatologistFields()
            ? [this.validateGmcNumberField(), this.validatePhoneNumberField(), this.validateAvailability()]
            : [];

        const validation = [
            this.validateSurnameLength(),
            this.validateAlternativeEmail(),
            ...additionalFieldValidation,
        ];

        return validation.every((validationResult) => Boolean(validationResult));
    }

    public render() {
        const { errorDetails, activeUser, mode, showModal, user } = this.state;

        const showAdditionalFields = this.showCallbackAgentOrDermatologistFields();
        const showLinkedUserField = this.showLinkedUserField();

        if (!userService.checkUserHasRole([UserRole.ADMIN, UserRole.SA_ADMIN, UserRole.SUPERADMIN])) {
            return <Redirect to="/home" />;
        }

        if (user === null) {
            return <LoadingSpinner />;
        }

        return (
            <Segment>
                <ActivateModal
                    activeUser={activeUser}
                    errorDetails={errorDetails as IFormError[]}
                    showModal={showModal}
                    closeModal={() => this.setState({ showModal: false })}
                    toggleUser={this.toggleUser}
                />
                <h2>User Details</h2>
                <Divider />
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            <Button disabled={!activeUser} floated="right" onClick={this.onModeChangeClick}>
                                {mode === ViewEditMode.VIEW ? "Edit" : "Cancel"}
                            </Button>
                            <Button onClick={this.onActivateDeactivateUser} floated="right">
                                {activeUser ? "Deactivate" : "Activate"}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Form onSubmit={this.submit}>
                    <Grid columns={1}>
                        <Grid.Row>
                            <Grid.Column>{this.getEmailField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getAlternativeEmail()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getTitle()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getNameField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getSurnameField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getRoleField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getAdditionalRoleInfoField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getPhoneNumberField()}</Grid.Column>
                        </Grid.Row>
                        {showAdditionalFields && (
                            <>
                                <Grid.Row>
                                    <Grid.Column>{this.getAvailabilityField()}</Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>{this.getGmcNumberField()}</Grid.Column>
                                </Grid.Row>
                            </>
                        )}
                        <Grid.Row>
                            <Grid.Column>{this.getOrganisationField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getNotesField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getLastLoginDate()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getLoginCount()}</Grid.Column>
                        </Grid.Row>
                        {showLinkedUserField && (
                            <Grid.Row>
                                <Grid.Column>{this.getLinkedUserField()}</Grid.Column>
                            </Grid.Row>
                        )}
                        {this.getSubmitButton()}
                    </Grid>
                </Form>
            </Segment>
        );
    }
}

export default UserDetailsScreen;
