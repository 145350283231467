import { FC, useEffect, useState, useMemo, useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";

import CustomMessage, { CustomMessageType } from "components/templates/CustomMessage";
import ResendOTPInstructions from "components/MFA/ResendOTPInstruction";
import Countdown from "components/MFA/Countdown";
import MultiInputTextField from "components/MFA/MultiInputTextField";

import { handleKeyDown } from "helpers/input";

import { IErrorResponse } from "model/errors";
import HttpStatus from "model/httpStatus";
import {
    MFAVerificationChannel,
    otpGenericError,
    otpValidationError,
    OTP_LENGTH,
    OTP_SINGLE_INPUT_REGEXP,
    MFAButtons,
    MFAUserFriendlyChannel,
    TrustedDeviceStatus,
} from "model/mfa";

import * as actions from "redux/actions";
import { getPendingRequest } from "redux/selectors/data";
import getMFASetupState from "redux/selectors/mfa";

import userService from "services/userService";

import delay from "util/delay";

import "scss/MFASetup.scss";

const generateFieldNames = (count: number): string[] => Array.from({ length: count }, (_, i) => `input-${i}`);

export interface IVerifyOTP {
    channel: MFAVerificationChannel;
    title: string;
    showTrustedDeviceCheckbox?: boolean;
    showVerificationChannelSwitch?: boolean;
    switchChannel?: () => void;
    handleVerification?: (shouldTrustDevice?: boolean) => void;
    isMobileNumberReverificationScenario?: boolean;
}

const VerifyOTP: FC<IVerifyOTP> = memo(
    ({
        channel,
        title,
        showTrustedDeviceCheckbox,
        showVerificationChannelSwitch,
        switchChannel,
        handleVerification,
        isMobileNumberReverificationScenario = false,
    }) => {
        const dispatch = useDispatch();
        const loading = useSelector(getPendingRequest);
        const mfaState = useSelector(getMFASetupState);
        const mobileNumber = mfaState[MFAVerificationChannel.SMS].destination;
        const [shouldTrustDevice, setShouldTrustDevice] = useState<boolean>(
            mfaState.trustedDeviceStatus === TrustedDeviceStatus.ABOUT_TO_EXPIRE
        );
        const [otp, setOTP] = useState<string>("");
        const [hasCodeTimedOut, setHasCodeTimedOut] = useState<boolean>(false);
        const [apiErrorResponse, setApiErrorResponse] = useState<IErrorResponse>(null);
        const [hasVerifiedCodeSuccessfully, setHasVerifiedCodeSuccessfully] = useState<boolean>(false);
        const [otpCode, setOTPCode] = useState<string>(null);

        const isEmailChannel = channel === MFAVerificationChannel.EMAIL;
        const [alternativeChannel, setAlternativeChannel] = useState(
            isEmailChannel ? MFAVerificationChannel.SMS : MFAVerificationChannel.EMAIL
        );
        const [to, setTo] = useState(mfaState[channel].destination);

        const initiateNewOTP = useCallback(
            async (dest?: string): Promise<void> => {
                try {
                    setApiErrorResponse(null);
                    if (isEmailChannel) {
                        await userService.initiateEmailVerification();
                    } else {
                        const destination = isMobileNumberReverificationScenario ? "" : dest;
                        await userService.initiateSMSVerification(destination);
                    }
                    setHasCodeTimedOut(false);
                } catch (error: any) {
                    if (error?.response?.status === HttpStatus.TOO_MANY_REQUESTS) {
                        setApiErrorResponse({
                            message: error?.response?.data?.errors[0].message,
                            details: error?.response?.data?.errors[0]?.details?.[0].errorDescription,
                        });
                        setHasCodeTimedOut(true);
                    } else {
                        setApiErrorResponse({
                            message: otpGenericError.message,
                        });
                    }
                }
            },
            [channel]
        );

        const fieldNames = useMemo(() => generateFieldNames(OTP_LENGTH), []);
        type Inputs = Record<(typeof fieldNames)[number], string>;
        const defaultValues: Inputs = useMemo(
            () =>
                fieldNames.reduce((acc, key) => {
                    acc[key] = "";
                    return acc;
                }, {} as Inputs),
            []
        );

        const useFormInstance = useForm<Inputs>({ defaultValues });
        const {
            clearErrors,
            formState: { errors },
            handleSubmit,
            reset,
        } = useFormInstance;

        const handleNewCodeSent = (isSuccessful: boolean): void => {
            reset();
            setHasCodeTimedOut(isSuccessful);
            setApiErrorResponse(null);
        };

        const handleSwitchChannel = async (): Promise<void> => {
            reset();
            setApiErrorResponse(null);
            switchChannel();
        };

        const onSubmit: SubmitHandler<Inputs> = async (): Promise<void> => {
            try {
                setApiErrorResponse(null);
                clearErrors();
                setOTPCode(otp);
                const response = await userService.verifyCode({ code: otp, channel });
                setHasVerifiedCodeSuccessfully(true);
                await delay(1000);
                dispatch(
                    actions.mfaActions.mfaVerified({
                        channel,
                        verificationDate: response.data.verificationDate,
                    })
                );
                if (channel === MFAVerificationChannel.SMS) {
                    dispatch(actions.dataActions.mobileNumberUpdated({ mobileNumber }));
                }
                handleVerification(shouldTrustDevice);
            } catch (error: any) {
                if (error?.response) {
                    setApiErrorResponse({
                        message: error.response?.data?.errors[0].message,
                        details: error.response?.data?.errors[0]?.details?.[0].errorDescription,
                    });
                    if (error?.response?.status === HttpStatus.TOO_MANY_REQUESTS) {
                        setHasCodeTimedOut(true);
                    }
                }
            }
        };

        const errorConfig = Object.keys(errors)?.length > 0 ? otpValidationError : otpGenericError;

        useEffect(() => {
            setAlternativeChannel(isEmailChannel ? MFAVerificationChannel.SMS : MFAVerificationChannel.EMAIL);
            setTo(mfaState[channel].destination);
            initiateNewOTP(!isEmailChannel ? mobileNumber : undefined);
        }, [channel]);

        const onInputChange = (text: string): void => {
            setApiErrorResponse(null);
            setOTP(text);
        };

        if (hasCodeTimedOut) {
            return (
                <ResendOTPInstructions
                    channel={channel}
                    title={title}
                    code={otpCode}
                    handleNewCodeSent={handleNewCodeSent}
                    apiError={apiErrorResponse}
                />
            );
        }

        return (
            <Box>
                <h2>{title}</h2>
                <p className="otp-form-instructions">
                    Check your {isEmailChannel ? "inbox" : "messages"} and enter the {OTP_LENGTH}-digit code we sent to:{" "}
                    <strong>{to}</strong>
                </p>
                <Box className="otp-form-wrapper">
                    <Box className="full-width" component="form" onSubmit={handleSubmit(onSubmit)}>
                        {Object.keys(errors)?.length > 0 ? (
                            <CustomMessage
                                type={CustomMessageType.ERROR}
                                message={errorConfig.message}
                                cta={errorConfig.cta}
                                showDefaultHelp={errorConfig.showDefaultHelp}
                            />
                        ) : null}
                        {apiErrorResponse ? (
                            <CustomMessage
                                type={CustomMessageType.ERROR}
                                message={apiErrorResponse.message}
                                cta={apiErrorResponse.details}
                                showDefaultHelp={!apiErrorResponse.details}
                            />
                        ) : null}
                        <MultiInputTextField
                            onChange={onInputChange}
                            singleInputRegex={OTP_SINGLE_INPUT_REGEXP}
                            inputsNumber={OTP_LENGTH}
                            useFormInstance={useFormInstance}
                        />
                        {showTrustedDeviceCheckbox ? (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        aria-label={`${shouldTrustDevice ? "Disable" : "Enable"} trusted device`}
                                        checked={shouldTrustDevice}
                                        onChange={() => setShouldTrustDevice(!shouldTrustDevice)}
                                        name="trusted-device"
                                    />
                                }
                                label={
                                    <Box style={{ display: "flex", alignContent: "center" }}>
                                        Trust this device for the next 90 days
                                        <Tooltip
                                            title={
                                                <p style={{ fontSize: "14px" }}>
                                                    You can opt to trust this device to make your login process quicker
                                                    and smoother for the next 90 days. After 90 days, we will ask you to
                                                    re-authenticate to ensure your account remains secure.
                                                </p>
                                            }
                                            style={{ paddingLeft: "4px" }}
                                            placement="top"
                                        >
                                            <InfoOutlinedIcon />
                                        </Tooltip>
                                    </Box>
                                }
                            />
                        ) : null}
                        <Box className="mfa-button-wrapper">
                            <Button
                                className="full-width"
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loading || hasVerifiedCodeSuccessfully}
                            >
                                {loading ? <CircularProgress size="16px" /> : MFAButtons.CONTINUE}
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Countdown
                    channel={channel}
                    setHasErrored={setApiErrorResponse}
                    initiateNewOTP={() => {
                        initiateNewOTP(mobileNumber);
                    }}
                />
                {showVerificationChannelSwitch ? (
                    <Box className="flex-center flex-center__row">
                        or&nbsp;
                        <span
                            className="text-button text-button__heavy text-button__heavy--blue"
                            tabIndex={0}
                            role="button"
                            onKeyDown={(e) => handleKeyDown(e, handleSwitchChannel)}
                            onClick={handleSwitchChannel}
                        >
                            send code via {MFAUserFriendlyChannel[alternativeChannel]}
                        </span>
                    </Box>
                ) : null}
            </Box>
        );
    }
);
VerifyOTP.defaultProps = {
    showTrustedDeviceCheckbox: false,
    showVerificationChannelSwitch: false,
    switchChannel: null,
};

export default VerifyOTP;
