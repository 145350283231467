import { FC, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import CustomMessage, { CustomMessageType } from "components/templates/CustomMessage";

import { handleKeyDown } from "helpers/input";
import useCountdownTimer from "hooks/useCountdownTimer";

import { IErrorResponse } from "model/errors";
import HttpStatus from "model/httpStatus";
import { MFAUserFriendlyChannel, MFAVerificationChannel, otpGenericError } from "model/mfa";

import { getPendingRequest } from "redux/selectors/data";
import getMFASetupState from "redux/selectors/mfa";

import userService from "services/userService";

import "scss/MFASetup.scss";

interface IResendOTPInstruction {
    code: string;
    channel: MFAVerificationChannel;
    title: string;
    handleNewCodeSent: (isSuccessful: boolean) => void;
    apiError?: IErrorResponse;
}

const TWILIO_CODE_TIMEOUT = 10;

const ResendOTPInstruction: FC<IResendOTPInstruction> = ({ channel, title, code, handleNewCodeSent, apiError }) => {
    const loading = useSelector(getPendingRequest);
    const mfaState = useSelector(getMFASetupState);

    const [apiErrorResponse, setApiErrorResponse] = useState<IErrorResponse>(apiError);
    const { destination } = mfaState[channel];

    const { countdownTime, isCountdownFinished, resetCountdown } = useCountdownTimer(0);

    useEffect(() => {
        const now = dayjs();
        const timeTwilioResendAvailable = dayjs(mfaState[channel].codeCreationTime).add(TWILIO_CODE_TIMEOUT, "minute");
        const timeTillResendAvailableSeconds = timeTwilioResendAvailable.diff(now, "second");
        resetCountdown(mfaState[channel].codeCreationTime ? timeTillResendAvailableSeconds : 0);
    }, [mfaState[channel].codeCreationTime]);

    const requestNewOTP = async (): Promise<void> => {
        setApiErrorResponse(null);
        try {
            if (code) {
                await userService.cancelVerificationAttempt({ channel, code, destination });
            }
            if (channel === MFAVerificationChannel.EMAIL) {
                await userService.initiateEmailVerification();
            } else {
                await userService.initiateSMSVerification(destination);
            }
            handleNewCodeSent(false);
        } catch (error: any) {
            if (error?.response?.status === HttpStatus.TOO_MANY_REQUESTS) {
                setApiErrorResponse({
                    message: error?.response?.data?.errors[0].message,
                    details: error?.response?.data?.errors[0]?.details?.[0].errorDescription,
                });
            } else {
                setApiErrorResponse({
                    message: otpGenericError.message,
                });
            }
        }
    };

    function formatTimeRemaining(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const paddedMinutes = String(minutes).padStart(2, "0");
        const paddedSeconds = String(remainingSeconds).padStart(2, "0");

        return `${paddedMinutes}:${paddedSeconds}`;
    }

    const formattedCountdownTime = formatTimeRemaining(countdownTime);

    return (
        <Box>
            <h2>{title}</h2>
            <p className="otp-form-instructions">
                There was an issue with your previous code. Request a new one to try again.
            </p>
            <Box className="resend-code-content">
                <CustomMessage
                    type={CustomMessageType.INFO}
                    message="Remember that the code is only valid for 10 minutes"
                />
                {apiErrorResponse?.message ? (
                    <CustomMessage
                        type={CustomMessageType.ERROR}
                        message={apiErrorResponse.message}
                        cta={apiErrorResponse?.details}
                        showDefaultHelp={!apiErrorResponse?.details}
                    />
                ) : null}
            </Box>
            <Box className="mfa-button-wrapper">
                <Button
                    className="full-width sentence-case disabled-button"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isCountdownFinished || loading}
                    onClick={requestNewOTP}
                    onKeyDown={(e) => handleKeyDown(e, requestNewOTP)}
                >
                    {loading ? (
                        <CircularProgress size="16px" />
                    ) : (
                        `Resend ${MFAUserFriendlyChannel[channel]} with new code ${
                            !isCountdownFinished ? `in ${formattedCountdownTime}` : ""
                        }`
                    )}
                </Button>
            </Box>
        </Box>
    );
};

export default ResendOTPInstruction;
