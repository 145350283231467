import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { handleKeyDown } from "helpers/input";

import CustomMessage, { CustomMessageType } from "components/templates/CustomMessage";

import { MFAButtons } from "model/mfa";

import getMFASetupState from "redux/selectors/mfa";

import { homeScreenInitialisation } from "services/authorizationService";

import "scss/MFASetup.scss";

interface IMobileNumberReverificationInfo {
    handleMobileNumberUpdate: () => void;
    handleMobileNumberReverification: () => void;
}

const MobileNumberReverificationInfo: FC<IMobileNumberReverificationInfo> = ({
    handleMobileNumberUpdate,
    handleMobileNumberReverification,
}) => {
    const dispatch = useDispatch();
    const { sms } = useSelector(getMFASetupState);
    const customMessage = `Your mobile number \n${sms.destination ?? ""}`;

    const handleSkipReverification = (): void => {
        homeScreenInitialisation(dispatch);
    };

    return (
        <Box>
            <Box>
                <h2>Help us make sure your details are correct</h2>
                <Box>
                    <p>
                        We want to ensure your details are always up to date. Since you have not used the mobile
                        verification for a while, please verify your number to confirm it is still correct.
                    </p>
                    <CustomMessage
                        buttonHandler={handleMobileNumberUpdate}
                        buttonText="Change"
                        type={CustomMessageType.INFO}
                        message={customMessage}
                    />
                </Box>

                <Box display="flex" alignItems="center" flexDirection="column" paddingTop={2}>
                    <Box marginBottom={2} marginTop={2} width="100%">
                        <Button
                            className="full-width sentence-case"
                            variant="contained"
                            color="primary"
                            onClick={handleMobileNumberReverification}
                            onKeyDown={(e) => handleKeyDown(e, handleMobileNumberReverification)}
                        >
                            {MFAButtons.VERIFY_MOBILE_NUMBER}
                        </Button>
                    </Box>
                    <Box
                        className="text-button text-button__heavy text-button__heavy--blue"
                        tabIndex={0}
                        role="button"
                        onKeyDown={(e) => handleKeyDown(e, handleSkipReverification)}
                        onClick={handleSkipReverification}
                    >
                        Do it later
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default MobileNumberReverificationInfo;
