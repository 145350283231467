import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Divider, Segment } from "semantic-ui-react";
import { UAParser } from "ua-parser-js";

import { history } from "App";

import CustomMessage, { CustomMessageType } from "components/templates/CustomMessage";

import { ITrustedDevice, trustedDeviceRemovalError } from "model/mfa";
import { IErrorResponse } from "model/errors";

import * as actions from "redux/actions";
import getMFASetupState from "redux/selectors/mfa";
import { getUser } from "redux/selectors/data";

import { CHANGE_MOBILE_NUMBER, CHANGE_PASSWORD } from "navigation/routes";

import logoutTimeoutService from "services/logoutTimeoutService";
import userService from "services/userService";

import MfaSetting from "./MfaSetting";
import TrustedDeviceRemovalConfirmation from "./TrustedDeviceRemovalConfirmation";

import "scss/UserSettings.scss";

const UserSettings: FC = () => {
    const [trustedDevices, setTrustedDevices] = useState<ITrustedDevice[]>([]);
    const [selectedDeviceUuid, setSelectedDeviceUuid] = useState<string | null>(null);
    const [selectedDeviceName, setSelectedDeviceName] = useState<string | null>(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [apiErrorResponse, setApiErrorResponse] = useState<IErrorResponse>(null);

    const dispatch = useDispatch();

    const mfaState = useSelector(getMFASetupState);
    const userState = useSelector(getUser);

    useEffect(() => {
        const getTrustedDevices = async (): Promise<void> => {
            const orderedDevices = [];
            const fetchedDevices = await userService.getTrustedDevices();
            const currentSessionDevice = fetchedDevices.find((device) => device.uuid === mfaState.trustedDeviceUuid);
            if (currentSessionDevice) {
                orderedDevices.push(currentSessionDevice);
            }
            const otherSessions = fetchedDevices
                .filter((device) => device.uuid !== mfaState.trustedDeviceUuid)
                .sort((device1, device2) => dayjs(device2.lastActivityDate).diff(dayjs(device1.lastActivityDate)));
            orderedDevices.push(...otherSessions);
            setTrustedDevices(orderedDevices);
        };
        if (mfaState.isEnabledForAnyOrganisation) {
            getTrustedDevices();
        }
    }, []);

    const mobileVerificationText = `Mobile phone authentication is set with the number ${userState.mobileNumber}`;
    const emailVerificationText = `Email authentication is set with the email ${userState.email}`;

    const openConfirmation = (uuid: string, name: string): void => {
        setShowConfirmation(true);
        setSelectedDeviceUuid(uuid);
        setSelectedDeviceName(name);
    };

    const closeConfirmation = (): void => {
        setShowConfirmation(false);
        setSelectedDeviceUuid(null);
        setSelectedDeviceName(null);
    };

    const goToChangePassword = () => {
        history.push(CHANGE_PASSWORD);
    };

    const goToMobileManagement = () => {
        history.push(CHANGE_MOBILE_NUMBER);
        dispatch(actions.mfaActions.initMobileNumberChange());
    };

    const handleClick = () => {
        logoutTimeoutService.startCount();
    };

    const removeDevice = async (uuid: string): Promise<void> => {
        try {
            setApiErrorResponse(null);
            await userService.removeTrustedDevice(uuid);
            const updatedDevices = trustedDevices.filter((device) => device.uuid !== uuid);
            setTrustedDevices(updatedDevices);
        } catch (error) {
            setApiErrorResponse({
                message: trustedDeviceRemovalError.message,
            });
        }
        setShowConfirmation(false);
        setSelectedDeviceUuid(null);
        setSelectedDeviceName(null);
    };

    return (
        <Container onClick={handleClick}>
            <Segment className="user-settings">
                <h1>Settings</h1>
                <h3>Password</h3>
                <Divider />
                <Link className="user-settings-link" to={CHANGE_PASSWORD} onClick={goToChangePassword}>
                    Change password
                </Link>
                {mfaState.isEnabledForAnyOrganisation ? (
                    <>
                        <h3>Multi-factor authentication</h3>
                        <Divider />
                        {mfaState?.isMFAActivated ? (
                            <div>
                                <MfaSetting
                                    text={mobileVerificationText}
                                    changeUrlRoute={CHANGE_MOBILE_NUMBER}
                                    changeUrlFunc={goToMobileManagement}
                                />
                                <MfaSetting text={emailVerificationText} />
                            </div>
                        ) : (
                            <p>
                                You haven&apos;t set up your multi-factor authentication methods yet. Please do so the
                                next time you log in to keep your account secure.
                            </p>
                        )}

                        <h3>Trusted devices</h3>
                        <Divider />
                        {apiErrorResponse ? (
                            <CustomMessage type={CustomMessageType.ERROR} message={apiErrorResponse.message} />
                        ) : null}
                        {trustedDevices.length
                            ? trustedDevices
                                  .map((device: ITrustedDevice) => {
                                      const { os, browser } = UAParser(device.userAgent);
                                      const name = `${os.name} (${browser.name})`;
                                      let lastActiveInfo = "";
                                      if (device.lastActivityDate) {
                                          lastActiveInfo =
                                              device.uuid === mfaState.trustedDeviceUuid
                                                  ? "Current session"
                                                  : `Last active ${dayjs(device.lastActivityDate).format(
                                                        "DD/MM/YYYY [at] HH:mm"
                                                    )}`;
                                      }
                                      return { name, uuid: device.uuid, lastActiveInfo };
                                  })
                                  .map((item) => (
                                      <div key={item.uuid} className="trusted-device-item">
                                          <div className="trusted-device-data">
                                              <div>{item.name}</div>
                                              <div className="last-activity-info">{item.lastActiveInfo}</div>
                                          </div>
                                          <div className="trusted-device-actions">
                                              <Link to="#/" onClick={() => openConfirmation(item.uuid, item.name)}>
                                                  Remove
                                              </Link>
                                          </div>
                                      </div>
                                  ))
                            : "-"}
                        <TrustedDeviceRemovalConfirmation
                            show={showConfirmation}
                            closeOnOverlay={false}
                            name={selectedDeviceName}
                            onConfirm={() => removeDevice(selectedDeviceUuid)}
                            onClose={closeConfirmation}
                        />
                    </>
                ) : null}
            </Segment>
        </Container>
    );
};

export default UserSettings;
