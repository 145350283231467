import React, { FC, useState, useContext, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Table } from "semantic-ui-react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";

import AllocateCaseModal from "components/CaseList/AllocateCaseModal";
import AbandonDialog from "components/templates/dialog/AbandonCaseDialog";
import CloseCaseDialog from "components/templates/dialog/CloseCaseDialog";
import ReopenCaseDialog from "components/templates/dialog/ReopenCaseDialog";
import useRedirectToCaseAssessment from "components/CaseList/hooks/useRedirectToAssessment";

import { CaseListContext } from "contextProviders/CaseListProvider";

import { isUndefined } from "helpers/common";
import { shouldRedirectToQRCodePage } from "helpers/cases";

import { IAssignment, ICase } from "model/case";
import { CaseListTabs } from "model/caseList";
import { CaseStatus } from "model/caseStatus";
import { ReviewMode } from "model/caseViewMode";
import { KitDeliveryStatuses } from "model/remoteModel";
import { UserRole } from "model/userRole";

import { history } from "App";
import { CASE_DESCRIPTION } from "navigation/routes";

import { getOrganisation, getUser } from "redux/selectors/data";

import * as caseService from "services/caseService";
import logoutTimeoutService from "services/logoutTimeoutService";

interface ICaseListContextMenu {
    currentCase: ICase;
    currentUserRole: string;
    additionalClassName: string;
}

const CaseListContextMenu: FC<ICaseListContextMenu> = ({ currentCase, currentUserRole, additionalClassName }) => {
    const [showAbandonDialog, setShowAbandonDialog] = useState<boolean>(false);
    const [showCloseDialog, setShowCloseDialog] = useState<boolean>(false);
    const [showReopenDialog, setShowReopenDialog] = useState<boolean>(false);
    const [showAllocationModal, setShowAllocationModal] = useState<boolean>(false);
    const [isRemoveAllocation, setIsRemoveAllocation] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { activeTab, isKitDeliveryStatusTab, updateCaseStatus, updateKitDeliveryStatus } =
        useContext(CaseListContext);
    const organisation = useSelector(getOrganisation);
    const user = useSelector(getUser);
    const dispatch = useDispatch();
    const { redirectToAssessment } = useRedirectToCaseAssessment();

    const {
        uuid,
        caseStatus,
        kitDeliveryStatus: currentKitDeliveryStatus,
        remote,
        homeInitiated,
        lesions,
        assignmentDetails,
    } = currentCase;

    const { uuid: userUuid } = user;

    const isCurrentUserClinician = currentUserRole === UserRole.CLINICIAN;
    const isCurrentUserAdmin = currentUserRole === UserRole.ADMIN;
    const isCurrentUserDerm = currentUserRole === UserRole.DERMATOLOGIST;
    const isCurrentUserSuperAdmin = currentUserRole === UserRole.SUPERADMIN;
    const isCurrentUserSaAdmin = currentUserRole === UserRole.SA_ADMIN;
    const isCurrentUserCallbackAgent = currentUserRole === UserRole.CALLBACK_AGENT;

    const isCurrentCaseStatusCreated = caseStatus === CaseStatus.CASE_CREATED;
    const isCurrentCaseStatusInProgress = caseStatus === CaseStatus.CASE_IN_PROGRESS;
    const isCurrentCaseStatusForSaReview = caseStatus === CaseStatus.CASE_FOR_SA_REVIEW;
    const isCurrentCaseStatusForReview = caseStatus === CaseStatus.CASE_FOR_REVIEW;
    const isCurrentCaseStatusFailed = caseStatus === CaseStatus.CASE_FAILED;
    const isCurrentCaseStatusCompleted = caseStatus === CaseStatus.CASE_COMPLETED;
    const isCurrentCaseStatusClosed = caseStatus === CaseStatus.CASE_CLOSED;
    const isCurrentCaseStatusAbandoned = caseStatus === CaseStatus.CASE_ABANDONED;
    const isCurrentCaseStatusInSaReview = caseStatus === CaseStatus.CASE_IN_SA_REVIEW;
    const isCurrentCaseStatusInOrgReview = caseStatus === CaseStatus.CASE_IN_REVIEW;
    const isCurrentCaseStatusInReview = isCurrentCaseStatusInOrgReview || isCurrentCaseStatusInSaReview;

    const showContinueButtonHomeInitiated =
        homeInitiated && (isCurrentUserClinician || (isCurrentUserSuperAdmin && shouldRedirectToQRCodePage(lesions)));

    const showContinueMenuItem =
        (isCurrentUserSuperAdmin || isCurrentUserClinician) &&
        (isCurrentCaseStatusCreated || isCurrentCaseStatusInProgress) &&
        !remote &&
        (!homeInitiated || showContinueButtonHomeInitiated);
    const showAbandonMenuItem =
        showContinueMenuItem ||
        ((isCurrentCaseStatusCreated || isCurrentCaseStatusInProgress) &&
            ((!remote && isCurrentUserAdmin) || isCurrentUserSaAdmin)) ||
        (isCurrentUserSuperAdmin && !isCurrentCaseStatusClosed && !isCurrentCaseStatusAbandoned);
    const showAllocateMenuItem =
        (isCurrentCaseStatusForSaReview && (isCurrentUserSuperAdmin || isCurrentUserSaAdmin)) ||
        (isCurrentUserAdmin && isCurrentCaseStatusForReview);
    const showReallocateMenuItem =
        (isCurrentUserAdmin && isCurrentCaseStatusInOrgReview) ||
        ((isCurrentUserSaAdmin || isCurrentUserSuperAdmin) && isCurrentCaseStatusInReview);
    const showUnallocateMenuItem = showReallocateMenuItem;
    const isViewCaseForSaReviewAllowed =
        isCurrentCaseStatusForSaReview && (isCurrentUserAdmin || isCurrentUserClinician);
    const isViewCaseForReviewAllowed =
        (isCurrentUserSuperAdmin || isCurrentUserSaAdmin) && isCurrentCaseStatusForReview;
    const isViewCaseInReviewFailedCompletedAllowed =
        (isCurrentUserSuperAdmin || isCurrentUserSaAdmin || isCurrentUserClinician) &&
        (isCurrentCaseStatusInReview || isCurrentCaseStatusFailed || isCurrentCaseStatusCompleted);
    const isViewCaseClosedAbandonedAllowed =
        (isCurrentUserAdmin || isCurrentUserSuperAdmin || isCurrentUserSaAdmin) &&
        (isCurrentCaseStatusClosed || isCurrentCaseStatusAbandoned);
    const isViewCaseAllowedForAdmin =
        isCurrentUserAdmin &&
        (isCurrentCaseStatusInSaReview || isCurrentCaseStatusInReview || isCurrentCaseStatusCompleted);
    const isCurrentCaseAssignedToCurrentUser = assignmentDetails?.assigneeUuid === userUuid;

    const showViewMenuItem =
        !isCurrentCaseAssignedToCurrentUser &&
        (isViewCaseForSaReviewAllowed ||
            isViewCaseForReviewAllowed ||
            isViewCaseInReviewFailedCompletedAllowed ||
            isViewCaseClosedAbandonedAllowed ||
            isViewCaseAllowedForAdmin ||
            isCurrentUserCallbackAgent ||
            remote ||
            !showContinueButtonHomeInitiated);

    const showReviewMenuItem = isCurrentUserDerm && isCurrentCaseStatusInReview && isCurrentCaseAssignedToCurrentUser;

    const showCloseMenuItem =
        ((!remote && isCurrentUserAdmin) ||
            isCurrentUserSuperAdmin ||
            isCurrentUserSaAdmin ||
            isCurrentUserClinician) &&
        isCurrentCaseStatusCompleted;

    const showDownloadReportMenuItem =
        (isCurrentUserAdmin ||
            isCurrentUserSuperAdmin ||
            isCurrentUserSaAdmin ||
            isCurrentUserClinician ||
            isCurrentUserDerm) &&
        (isCurrentCaseStatusCompleted || isCurrentCaseStatusClosed || isCurrentCaseStatusAbandoned);

    const showReopenMenuItem =
        ((!remote && isCurrentUserAdmin) ||
            isCurrentUserSuperAdmin ||
            isCurrentUserSaAdmin ||
            isCurrentUserClinician) &&
        isCurrentCaseStatusClosed;

    const showKitDeliveryStatusMenuItem =
        (isCurrentUserAdmin || isCurrentUserSuperAdmin) &&
        isKitDeliveryStatusTab() &&
        activeTab !== CaseListTabs.TO_DISPATCH &&
        remote &&
        !isUndefined(currentKitDeliveryStatus);

    const showMenu =
        showContinueMenuItem ||
        showAbandonMenuItem ||
        showAllocateMenuItem ||
        showReallocateMenuItem ||
        showUnallocateMenuItem ||
        showViewMenuItem ||
        showReviewMenuItem ||
        showDownloadReportMenuItem ||
        showReopenMenuItem ||
        showKitDeliveryStatusMenuItem;

    const closeContextMenu = () => {
        setAnchorEl(null);
    };

    const abandonCase = (reason?: string) => {
        caseService.abandonCase(uuid, user, reason).then(() => {
            updateCaseStatus(uuid, CaseStatus.CASE_ABANDONED);
        });
    };

    const closeCase = () => {
        caseService.closeCase(uuid).then(() => {
            updateCaseStatus(uuid, CaseStatus.CASE_CLOSED);
        });
    };

    const reopenCase = () => {
        caseService.reopenCase(uuid).then(() => {
            updateCaseStatus(uuid, CaseStatus.CASE_COMPLETED);
            setShowReopenDialog(false);
        });
    };

    const handleContinueClicked = async () => {
        redirectToAssessment(uuid, false);
    };

    const handleUpdateKitDeliveryStatus = () => {
        if (isUndefined(currentKitDeliveryStatus)) {
            return;
        }

        const newKitDeliveryStatus =
            currentKitDeliveryStatus === KitDeliveryStatuses.DELIVERED
                ? KitDeliveryStatuses.DISPATCHED
                : KitDeliveryStatuses.DELIVERED;

        caseService.updateKitDeliveryStatus(uuid, newKitDeliveryStatus).then(() => {
            updateKitDeliveryStatus(uuid, newKitDeliveryStatus, currentKitDeliveryStatus as KitDeliveryStatuses);
        });
    };

    const handleUpdateCaseStatus = (caseUuid: string, status: CaseStatus, newAssignee?: IAssignment) => {
        updateCaseStatus(caseUuid, status, newAssignee);
    };

    const handleAllocateToDermatologist = (removeAllocation: boolean) => {
        if (removeAllocation) {
            setIsRemoveAllocation(true);
        } else {
            setIsRemoveAllocation(false);
        }

        setShowAllocationModal(true);
    };

    const handleAbandonCase = () => {
        closeContextMenu();
        setShowAbandonDialog(true);
    };

    const handleViewCase = () => {
        history.push(`${CASE_DESCRIPTION}/${uuid}`);
    };

    const handleReview = () => {
        history.push(`${CASE_DESCRIPTION}/${uuid}/${ReviewMode.REVIEW}`);
    };

    const handleClose = () => {
        closeContextMenu();
        setShowCloseDialog(true);
    };

    const handleDownloadReport = () => {
        dispatch(caseService.downloadReport(uuid));
        closeContextMenu();
    };

    const toggleReopenCase = () => {
        setShowReopenDialog(!showReopenDialog);
        closeContextMenu();
    };

    const kitDeliveryContextMenuTitle =
        currentKitDeliveryStatus === KitDeliveryStatuses.DELIVERED ? "Mark as Kit Dispatched" : "Mark as Kit Delivered";

    const handleItemClick = (e: MouseEvent<HTMLElement>) => {
        logoutTimeoutService.startCount();
        setAnchorEl(e.currentTarget);
    };

    return (
        <Table.Cell className={`case-list-menu-cell headcol ${additionalClassName}`}>
            {showMenu ? (
                <div className="case-list-context-menu-button">
                    <IconButton onClick={handleItemClick} disableRipple>
                        <Icon circular name="ellipsis vertical" color="grey" inverted />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        onClose={closeContextMenu}
                    >
                        {showKitDeliveryStatusMenuItem && (
                            <MenuItem tabIndex={0} onClick={handleUpdateKitDeliveryStatus}>
                                {kitDeliveryContextMenuTitle}
                            </MenuItem>
                        )}
                        {showContinueMenuItem && (
                            <MenuItem tabIndex={0} onClick={handleContinueClicked}>
                                Continue Case
                            </MenuItem>
                        )}
                        {showAbandonMenuItem && (
                            <MenuItem tabIndex={0} onClick={handleAbandonCase}>
                                Abandon Case
                            </MenuItem>
                        )}
                        {showAllocateMenuItem && (
                            <MenuItem tabIndex={0} onClick={() => handleAllocateToDermatologist(false)}>
                                Allocate
                            </MenuItem>
                        )}
                        {showReallocateMenuItem && (
                            <MenuItem tabIndex={0} onClick={() => handleAllocateToDermatologist(false)}>
                                Change Allocation
                            </MenuItem>
                        )}
                        {showUnallocateMenuItem && (
                            <MenuItem tabIndex={0} onClick={() => handleAllocateToDermatologist(true)}>
                                Remove Allocation
                            </MenuItem>
                        )}
                        {showViewMenuItem && (
                            <MenuItem tabIndex={0} onClick={handleViewCase}>
                                View Case
                            </MenuItem>
                        )}
                        {showReviewMenuItem && (
                            <MenuItem tabIndex={0} onClick={handleReview}>
                                Review
                            </MenuItem>
                        )}
                        {showDownloadReportMenuItem && (
                            <MenuItem tabIndex={0} onClick={handleDownloadReport}>
                                Download Report
                            </MenuItem>
                        )}
                        {showCloseMenuItem && (
                            <MenuItem tabIndex={0} onClick={handleClose}>
                                Close
                            </MenuItem>
                        )}
                        {showReopenMenuItem && (
                            <MenuItem tabIndex={0} onClick={toggleReopenCase}>
                                Reopen Case
                            </MenuItem>
                        )}
                    </Menu>
                    <ReopenCaseDialog
                        showDialog={showReopenDialog}
                        reopenCase={reopenCase}
                        currentCase={currentCase}
                        closeDialog={toggleReopenCase}
                    />
                    {showCloseDialog && (
                        <CloseCaseDialog
                            showDialog={showCloseDialog}
                            callback={setShowCloseDialog}
                            caseObject={currentCase}
                            organisation={organisation}
                            closeCase={closeCase}
                        />
                    )}
                    <AbandonDialog
                        showDialog={showAbandonDialog}
                        callback={setShowAbandonDialog}
                        caseObject={currentCase}
                        organisation={organisation}
                        abandonCase={abandonCase}
                    />
                    {showAllocationModal && (
                        <AllocateCaseModal
                            isUnallocation={isRemoveAllocation}
                            updateCaseStatus={handleUpdateCaseStatus}
                            isSuperAdmin={isCurrentUserSuperAdmin || isCurrentUserSaAdmin}
                            currentCase={currentCase}
                            showDialog={showAllocationModal}
                            callback={setShowAllocationModal}
                        />
                    )}
                </div>
            ) : (
                <div />
            )}
        </Table.Cell>
    );
};

export default CaseListContextMenu;
