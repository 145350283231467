export const LOGIN = "/";
export const HOME = "/home";
export const ADMINISTRATION = "/administration";
export const CLINIC_LOCATION = "/clinic-location";
export const NEW_ASSESSMENT = "/new-assessment";
export const PATIENT_DETAILS = "/patient-details";
export const PATIENT_LOOK_UP = "/patient-lookup";
export const PATIENT_SEARCH = "/patient-search";
export const LESION_LOCATOR = "/lesion-locator";
export const IMAGE_CAPTURE = "/image-capture";
export const IMAGE_MANUAL_UPLOAD = "/image-manual-upload";
export const MEDICAL_HISTORY = "/medical-history";
export const SUN_EXPOSURE = `${MEDICAL_HISTORY}/sun-exposure`;
export const STUDY_IMAGE_CAPTURE = "/study-image-capture";
export const READY_FOR_ASSESSMENT = "/ready-for-assessment";
export const STUDY_ASSESSMENT_SUMMARY = "/study-assessment-summary";
export const AI_RESULTS = "/ai-results";
export const CHANGE_PASSWORD = "/change-password";
export const RESET_PASSWORD = "/reset-password";
export const CASE_DESCRIPTION = "/case-description";
export const CASE_RESULTS = "/case-results";
export const DIAGNOSIS = "/diagnosis";
export const UPDATE_DIAGNOSIS = "/update-diagnosis";
export const CASE_LIST = "/case-list";
export const UPLOADED_IMAGES = "/uploaded-images";
export const GUIDANCE = "/guidance";
export const CSV_REPORT = "/csv";
export const EDIT_PATIENT_DETAILS = "/edit/patient-details";
export const ASSESSMENT_MODE = "/assessment-mode";
export const REQUEST_IMG_REASSESSMENT = "/request-image-reassessment";
export const UPDATE_PASSWORD = "/update-password";
export const MFA_SETUP = "/mfa-setup";
export const MFA_AUTHENTICATION = "/mfa-authentication";
export const MFA_TRUSTED_DEVICE_REINITIALISATION = "/mfa-trusted-device-reinitialisation";
export const MFA_REVERIFY_MOBILE_NUMBER = "/mfa-reverify-mobile-number";
export const USER_SETTINGS = "/user-settings";
export const CHANGE_MOBILE_NUMBER = "/mobile-management";
export const REMOVE_TRUSTED_DEVICE = "/remove-trusted-device";
export const TEST = "/test";

export const VALID_APPLICATION_ROUTES = [
    HOME,
    CLINIC_LOCATION,
    NEW_ASSESSMENT,
    PATIENT_DETAILS,
    PATIENT_SEARCH,
    LESION_LOCATOR,
    IMAGE_CAPTURE,
    IMAGE_MANUAL_UPLOAD,
    MEDICAL_HISTORY,
    SUN_EXPOSURE,
    STUDY_IMAGE_CAPTURE,
    READY_FOR_ASSESSMENT,
    STUDY_ASSESSMENT_SUMMARY,
    AI_RESULTS,
    CHANGE_PASSWORD,
    RESET_PASSWORD,
    DIAGNOSIS,
    UPDATE_DIAGNOSIS,
    GUIDANCE,
    ASSESSMENT_MODE,
    CASE_LIST,
    UPLOADED_IMAGES,
    UPDATE_PASSWORD,
    MFA_SETUP,
    USER_SETTINGS,
    CHANGE_MOBILE_NUMBER,
    REMOVE_TRUSTED_DEVICE,
    MFA_REVERIFY_MOBILE_NUMBER,
    TEST,
];

export const CASE_DESCRIPTION_REGEXP = /\/case-description\//;
