import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";

import "scss/Maintenance.scss";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
interface IMaintenanceAdvancedWarning {
    isPublic: boolean;
}

const MaintenanceAdvancedWarning = ({ isPublic }: IMaintenanceAdvancedWarning) => {
    const [maintenanceData, setMaintenanceData] = useState<any>(null);

    useEffect(() => {
        // Data is outside of src folder so must use Fetch the retrieve maintenance data from the public folder
        fetch("/maintenance.json")
            .then((response) => response.json())
            .then((data) => setMaintenanceData(data))
            .catch((error) => console.error("Error fetching maintenance data:", error));
    }, []);

    if (!maintenanceData || !isPublic) {
        return null;
    }

    const { maintenanceDate, startTime, endTime, isMaintenancePlanned, incidentText } = maintenanceData;

    if (!isMaintenancePlanned || !maintenanceDate || !startTime || !endTime) {
        return null;
    }

    const currentDate = dayjs();
    const maintenanceStartDateTimeObject = dayjs(`${maintenanceDate} ${startTime}`, "DD/MM/YYYY HH:mm");
    const maintenanceEndDateTimeObject = dayjs(`${maintenanceDate} ${endTime}`, "DD/MM/YYYY HH:mm");

    if (maintenanceEndDateTimeObject.isBefore(currentDate)) {
        return null;
    }

    const maintenanceDateFormatted = maintenanceStartDateTimeObject.format("Do MMMM YYYY");
    const displayDate = currentDate.isSame(maintenanceStartDateTimeObject, "day") ? (
        <strong>TODAY</strong>
    ) : (
        <span>
            on the <strong>{maintenanceDateFormatted}</strong>
        </span>
    );
    const message = (
        <span>
            Skin Analytics will be performing routine maintenance {displayDate} between the hours of{" "}
            <strong>{startTime}</strong> and <strong>{endTime}</strong> GMT. The platform will be unavailable during
            this time. We apologise for any inconvenience this may cause.
        </span>
    );

    return <Box className="planned-maintenance-header">{incidentText || message}</Box>;
};

export default MaintenanceAdvancedWarning;
